//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

/********************************************/
/*     WARM BREEZE GAS CENTRAL HEATING   		*/
/********************************************/

/********************************************************/
/* WBG - Font family settings		  		             			*/
/********************************************************/

/*
Headings & Menu items: 
Note some of these have extra spacing between the letters

Body Text: 

/*
Header:
Menu: 
Hero: 
Intro Title: 
Intro Text: 
Cards Title: 
Cards Text: 
Cards Button: 

Profile Title: 
Profile Text: 

Testimonial: 
Testimonial name: 

Footer Title: 
Footer Link: 

$red: #ed1c24;
$blue: #2e3192;
$grey: #57595a;
$black: #000;
$white: #FFF;
$montserrat: "Montserrat", Arial, Helvetica, sans-serif;
$arial: Arial, Helvetica, sans-serif;
*/

$font-family-base: "Montserrat", Arial, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji";
$font-family-alt: Arial, "Montserrat", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji";

//$font-family-base: 'Nunito',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/********************************************************/
/* SEL - Colour settings 					                			*/
/********************************************************/

$grey-50: #fafafa !default;
$grey-100: #f5f5f5 !default;
$grey-200: #eeeeee !default;
$grey-300: #e0e0e0 !default;
$grey-400: #bdbdbd !default;
$grey-500: #9e9e9e !default;
$grey-600: #757575 !default;
$grey-700: #616161 !default; // Primary
$grey-800: #424242 !default;
$grey-900: #212121 !default;

$gray-900: #454444;
$white: #fff;
$grey: #57595a;
$black: #000;
$blackalt: #140001;
$dark: #1a1516;
$red: #ed1c24;
$blue: #2e3192;

$headings-color: $blue;
$body-color: $dark;

$h1-font-size: $font-size-base * 2.6;
$h2-font-size: $font-size-base * 1.9;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$primary: $blue !important;
$primary-light: $blue !default;
$primary-dark: $blue !default;
$link-color: $black;
$link-hover-color: $blue;
//$link-hover-color:  darken($link-color, 15%) !important;

/********************************************************/
/* SEL - Navbar overrides:				              	 			*/
/********************************************************/

$navbar-dark-color: rgba($white, 0.8);
$navbar-dark-hover-color: rgba($white, 1);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.5);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
);
$navbar-dark-toggler-border-color: rgba($white, 0.5);
